@import "../../styles/vars";

.button {
	cursor: pointer;
	border-radius: 4px;
	padding: $spacing-2 $spacing-4;
	font-size: 14px;

	transition: background-color 0.3s;
	transition: color 0.3s;
	
	&.primary {
		border: none;
		background-color: $color-darker-gray;
		color: $color-white;

		&:disabled {
			cursor: not-allowed;
			background-color: $color-medium-gray;
			color: $color-gray;
		}
	}

	&.secondary {
		border: 1px solid $color-gray;
		background-color: $color-white;
		color: $color-dark-gray;
	}

	&:disabled {
		cursor: not-allowed;
		color: $color-medium-gray;
	}
}