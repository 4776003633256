@import "../../styles/vars";

.container {
	height: 95vh;
	overflow-y: scroll;

	.visualizerItems {
		display: flex;
		flex-wrap: wrap;
		gap: $spacing-3;
		justify-content: center;
		margin: $spacing-3 0;
	}

	.paginatorContainer {
		display: flex;
		justify-content: center;
		margin: $spacing-3 0;
	}

	.fileInput {
		background-color: $color-white;
		display: flex;
		justify-content: center;
		padding: $spacing-4 0;
		position: sticky;
		top: 0;
		border-bottom: 1px solid $color-gray;
	}
}
