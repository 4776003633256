@import "../../styles/vars";

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    .inputRow {
        border-bottom: 1px solid $color-gray;
        padding: $spacing-6;

        .labelRow {
            display: flex;
            align-items: center;
            gap: $spacing-4;
            margin-bottom: $spacing-2;

            .inputLabel {
                font-weight: 600;
                font-size: 14px;
                color: $color-dark-gray;
            }

            .suggestCta {
                display: flex;
                align-items: center;
                gap: $spacing-1;
                color: $color-medium-gray;
                font-size: 14px;
                text-decoration: underline;
                cursor: pointer;
                transition: color 0.2s;

                &:hover {
                    color: $color-dark-gray;
                }
            }
        }

        .textArea {
            border: none;
            outline: none;
            width: 100%;
            font-family: $font-primary;
            font-size: 16px;
            font-weight: 200;
            line-height: 1.4;

            &::placeholder {
                color: $color-medium-gray;
            }
        }
    }

    .childrenRow {
        flex-grow: 1;
    }

    .controlRow {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: $color-white;
        border-bottom: 1px solid $color-gray;
        box-shadow: 0px 10px 10px 10px $color-medium-gray;
        justify-content: space-between;
        padding: $spacing-4 $spacing-6;
    }
}