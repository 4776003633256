@import "../../../../styles/vars";

.container {
	border: 1px solid $color-gray;
	background-color: $color-light-gray;
	border-radius: 4px;
	padding: $spacing-3;
	width: 500px;

	.detailLine {
		display: flex;
		align-items: baseline;
		gap: $spacing-1;
		margin-bottom: $spacing-1;
		color: $color-darker-gray;

		h5 {
			width: 75px;
		}

		span {
			font-size: 12px;
			width: 400px;
		}
	}

	.output {
		border-top: 1px solid $color-gray;
		padding-top: $spacing-3;

		h5 {
			color: $color-darker-gray;
		}

		.formattedOutput {
			font-size: 14px;
		}
	}
}
