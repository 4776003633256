// Standard
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 200;
	src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap");
}

// Standard
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
}

// Semi-bold
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 600;
	src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@600&display=swap");
}

// Bold
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src:url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
}

$font-primary: "Roboto", helvetica, sans-serif;