@import "../../styles/vars";

.container {
	display: flex;
	background-color: $color-light-gray;

	.column {
		display: flex;
		flex-direction: column;
		padding: $spacing-6;
		width: 50%;

		.resultHeader {
			display: flex;
			align-items: flex-end;
			height: 36px;
			justify-content: space-between;
			color: $color-dark-gray;

			h5 {
				border-bottom: 1px solid $color-gray;
				height: 22px;
			}
		}
	}

	.leftColumn {
		border-right: 1px solid $color-gray;
	}
}
