$color-light-gray: #f3f3f3;
$color-gray: #e0e0e0;
$color-medium-gray: #a5a5a5;
$color-dark-gray: #707070;
$color-darker-gray: #2c2c2c;
$color-almost-black: #1d1f21;

$color-bluish-gray: #2a313d;

$color-white: #ffffff;

$color-green: #41644a;
$color-light-green: #c8dbcf;
$color-lighter-green: #e3ece6;
