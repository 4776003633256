@import "../../styles/vars";

.container {
    color: $color-white;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    text-shadow: 0px 0px 3px $color-gray;
    width: 100%;

    h1 {
        font-weight: 600;
        font-size: 60px;
    }

    h3 {
        font-weight: 200;
        font-size: 20px;
    }

    button {
        margin-top: $spacing-4;
        scale: 1.2;
    }
}