@import "vars";

html {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	font-family: $font-primary;
}

body {
	margin: 0;
}

h1 {
	margin: 0;
	font-size: 28px;
}

h2 {
	margin: 0;
	font-size: 24px;
}

h5 {
	margin: 0;
}