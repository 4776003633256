@import "../../styles/vars";

.container {
    position: sticky;
    top:0;
    background-color: $color-white;
    display: flex;
    align-items: center;
    gap: $spacing-3;
    border-bottom: 1px solid $color-gray;
    padding: $spacing-5 $spacing-6 $spacing-4 $spacing-6;
}