@import "../../../../styles/vars";

.container {
	height: 0;
	box-shadow: 0 11px 8px -10px  $color-dark-gray;
	transition: height 0.2s;
	overflow: hidden;

	&.shown {
		height: 200px;
		margin-bottom: $spacing-2;
		padding-bottom: $spacing-3;
	}

	.suggestionsList {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		gap: $spacing-2;
		height: 100%;
		overflow-y: scroll;

		.prompt {
			border: 1px solid $color-green;
			border-radius: 4px;
			color: $color-bluish-gray;
			height: fit-content;
			font-size: 12px;
			background-color: $color-lighter-green;
			padding: $spacing-1 $spacing-2;
			cursor: pointer;
			transition: background-color 0.2s;
	
			&:hover {
				background-color: $color-light-green;
			}
		}
	}
}
