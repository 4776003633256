@import "../../styles/vars";

.languageHeader {
	background-color: $color-bluish-gray;
	border-radius: 4px 4px 0 0;
	margin-bottom: -$spacing-3;
	padding: $spacing-2 $spacing-3 $spacing-3 $spacing-3;
	color: $color-gray;
}


.codeBlock {
	border-radius: 4px;
	background-color: $color-almost-black;
	color: $color-light-gray;
	padding: $spacing-4;
}

.inlineCode {
	border-radius: 2px;
	padding: 2px $spacing-1;
	background-color: $color-dark-gray;
	color: $color-white;
}